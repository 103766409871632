<template>
  <div
    class="form-item grid"
    :class="{ required: required, disabled: disabled, error: isError }"
    :data-success="isSuccess"
  >
    <label v-if="label" class="form-item__label">
      {{ label }}
    </label>
    <input
      :type="type"
      :name="'form-item-' + uid"
      class="form-item__input pl-10 pr-10"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      @keypress="isLetter($event)"
      :maxlength="maxlength"
      :class="{ 't-capitalize': onlyLatin }"
      v-model="propValue"
    />
  </div>
</template>

<script setup>
import { getCurrentInstance, defineProps, defineEmits, toRefs, computed } from 'vue'
import DOMPurify from 'dompurify'

// Emits
const emits = defineEmits(['update:modelValue'])

// Props
const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  placeholder: {
    type: String,
    default: 'placeholder'
  },
  maxlength: {
    type: Number,
    default: -1
  },
  disabled: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  isError: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: String,
    default: ''
  },
  onlyLatin: {
    type: Boolean,
    default: false
  }
})

// Data
const { label, type, placeholder, required, modelValue, onlyLatin } = toRefs(props)
const uid = getCurrentInstance().uid

// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => {
    let str = val.trim()
    str = DOMPurify.sanitize(str)
    emits('update:modelValue', str)
  }
})

function isLetter(e) {
  if (onlyLatin.value) {
    let char = String.fromCharCode(e.keyCode)
    propValue.value = propValue?.value?.charAt(0).toUpperCase() + propValue?.value?.slice(1)
    if (/^[A-Za-z]+$/.test(char)) return true
    else e.preventDefault()
  }
}

const isSuccess = computed(() => {
  return !!propValue.value
})
</script>
