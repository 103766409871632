// // import { cutFullName, formatRuDateTime } from '@/plugins/helpers'

let cols = [
  {
    name: '№',
    key: 'id',
    filter: false,
    sort: false,
    show: true,
    disabled: false,
    width: '70px'
  },
  {
    name: 'Название заявки',
    key: 'name',
    filter: false,
    sort: false,
    show: true,
    disabled: false,
    width: 'auto'
  },
  {
    name: 'Проект',
    key: 'company',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: 'auto'
  },
  {
    name: 'Тип заявки',
    key: 'type',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: 'auto'
  },
  {
    name: 'Статус',
    key: 'status',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: 'auto'
  },
  {
    name: 'Исполнитель',
    key: 'responsible',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: 'auto'
  },
  {
    name: 'Инициатор',
    key: 'owner',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: 'auto'
  },
  {
    name: 'Для кого',
    key: 'for_user',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: 'auto'
  },
  {
    name: 'Дата создания',
    key: 'created_at',
    filter: false,
    sort: true,
    show: true,
    disabled: false,
    width: '170px',
    canHide: true
  }
]

const isSysAdmin = localStorage.getItem('isSysAdmin')
if (isSysAdmin !== 'Sysadmin') {
  cols = cols.filter((el) => el.key !== 'name')
}

export default cols
