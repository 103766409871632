<template>
  <div class="tabl__head-cell flex ai-c ggap-5 jc-sb cut" :style="{ width: col.width, flex: col.flex }">
    <div class="flex ai-c ggap-5">
      <button v-if="col.key === 'id' && groupBy" class="btn sm rounded cube-20" @click="emits('toggleAll')">
        <BaseIcon class="ic-16 black" :icon="isOpenAll ? 'arrow-down' : 'arrow-right'" />
      </button>
      <div class="cut">
        {{ col.name }}
      </div>
    </div>
    <div class="flex ai-c ggap-5">
      <!-- Filters -->
      <div class="col-filter" v-click-outside="closeAction">
        <button
          v-if="col.filter"
          class="btn xsm pl-5 pr-5"
          :class="{ active: filters, outline: isOpen, 'transparent-grey': !isOpen }"
          v-tippy="'Фильтр по колонке'"
          :data-btn="uniqId"
          @click="toggleAction"
        >
          <BaseIcon class="ic-14" :class="isOpen ? 'primary' : 'grey'" icon="filter" />
        </button>

        <!-- Drop down -->
        <div v-if="isOpen" class="col-filter__body box grid fs-14 overflow-h" :data-body="uniqId">
          <BaseLoad v-if="isLoad" class="white z2" />
          <div class="flex ai-c ggap-5 p-10 t-black">
            <span>Фильтр по колонке:</span>
            <h4 class="title">{{ col.name }}</h4>
          </div>
          <hr class="m-0" />

          <!-- Search -->
          <div v-if="col.key === 'author' || col.key === 'responsible'" class="col-filter__body-section search p-10">
            <div class="grid pos-r">
              <label :for="col.key" class="search__label flex ai-c jc-c">
                <BaseIcon class="ic-16 primary" icon="search" />
              </label>
              <input
                type="search"
                :name="col.key"
                :id="col.key"
                class="form-item__input pl-40 pr-15"
                placeholder="Начните писать..."
                autocomplete="off"
                v-model="filters"
              />
            </div>
          </div>

          <!-- Status -->
          <div
            v-if="col.key === 'status'"
            class="col-filter__body-section check-list statuses flex fw ggap-5 overflow-a p-10"
          >
            <button
              v-for="el of optionsStatuses"
              :key="el.id"
              class="statuses__tag flex ai-c ggap-5"
              type="button"
              @click="setStatus(el.id)"
            >
              <div class="check-list__item box flex ai-c jc-c" :class="{ active: isActiveStatus(el.id) }">
                <BaseIcon class="ic-14 grey" icon="check" />
              </div>
              <small class="marker" :style="{ color: el.text_color, backgroundColor: el.background_color }">
                {{ el.title }}
              </small>
            </button>
          </div>

          <!-- Project -->
          <div v-if="col.key === 'project'" class="col-filter__body-section check-list projects grid overflow-a">
            <button
              v-for="el of optionsProjects"
              :key="el.id"
              class="projects__tag flex ai-c ggap-10 w-100 cut"
              type="button"
              @click="setProject(el.id)"
            >
              <div class="check-list__item box flex ai-c jc-c" :class="{ active: isActiveProject(el.id) }">
                <BaseIcon class="ic-14 grey" icon="check" />
              </div>
              <span class="cut">
                <b>{{ el.text || 'Нет имени' }}</b> - {{ el.item.llp }}
              </span>
            </button>
          </div>

          <div class="p-10 grid">
            <button type="button" class="btn cancel sm jc-c" :disabled="!filters" @click.prevent="resetFilter">
              Сбросить
            </button>
          </div>
        </div>
      </div>

      <!-- Sort -->
      <button
        v-if="col.sort"
        class="btn transparent-grey xsm pl-5 pr-5"
        :class="{ active: sortBy.key === col.key, rotate: sortBy.key === col.key && sortBy.direction === 'asc' }"
        v-tippy="'Сортировка'"
        @click.stop.prevent="setSortBy(col.key)"
      >
        <BaseIcon class="ic-14 grey" icon="sort" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { defineEmits, defineProps, toRefs, computed, ref, watch, reactive, watchEffect } from 'vue'
import { BaseLoad, BaseIcon } from '@/components'
import { calcCoords } from '@/plugins'

// Emits
const emits = defineEmits(['toggleAll'])

// Props
const props = defineProps(['col', 'isOpenAll'])

// Data
const { col, isOpenAll } = toRefs(props)
const store = useStore()
const uniqId = 'filter-' + col.value.key
const isLoad = ref(false)
const isOpen = ref(false)
const formData = reactive({
  status: [],
  project: [],
  counterparty: []
})

// Computed
const optionsStatuses = computed(() => store.getters['helpers/statuses'] || [])
const optionsProjects = computed(() => store.getters.projects || [])
const optionsCounterparties = computed(() => store.getters.counterparties || [])
const sortBy = computed(() => store.getters['orders/sort'])
const groupBy = computed(() => store.getters['orders/tableGroupedBy'])
const filters = computed({
  get: () => store.getters['orders/filters'][col.value.key],
  set: (val) => store.commit('orders/SET_FILTERS', { key: col.value.key, value: val })
})

// Watch
watch(isOpen, async (val) => {
  if (val && col.value.key === 'status' && !optionsStatuses.value?.length) {
    isLoad.value = true
    await store.dispatch('helpers/GET_STATUSES')
    calcCoords(uniqId, 'ltr')
    isLoad.value = false
  }
  if (val && col.value.key === 'project' && !optionsProjects.value?.length) {
    isLoad.value = true
    await store.dispatch('GET_PROJECTS')
    calcCoords(uniqId, 'ltr')
    isLoad.value = false
  }
  if (val && col.value.key === 'counterparty_name' && !optionsCounterparties.value?.length) {
    isLoad.value = true
    await store.dispatch('GET_COUTERPARTIES')
    calcCoords(uniqId, 'ltr')
    isLoad.value = false
  }
})
watchEffect(() => {
  if (col.value.key === 'status') {
    formData.status = filters.value?.length ? filters.value : []
  }
  if (col.value.key === 'project') {
    formData.project = filters.value?.length ? filters.value : []
  }
  if (col.value.key === 'counterparty_name') {
    formData.counterparty = filters.value?.length ? filters.value : []
  }
})

// Methods
async function toggleAction() {
  isOpen.value = !isOpen.value
  calcCoords(uniqId, 'ltr')
}
function resetFilter() {
  filters.value = ''
  isOpen.value = false
}
function closeAction() {
  isOpen.value = false
}
function setSortBy(key) {
  store.commit('orders/SET_SORT', key)
}

// Methods:status
function setStatus(id) {
  const index = formData.status.findIndex((el) => el === id)
  if (index === -1) formData.status.push(id)
  else formData.status.splice(index, 1)

  filters.value = formData.status?.length ? formData.status : ''
}
function isActiveStatus(id) {
  return formData.status.findIndex((el) => el === id) !== -1
}

// Methods:project
function setProject(id) {
  const index = formData.project.findIndex((el) => el === id)
  if (index === -1) formData.project.push(id)
  else formData.project.splice(index, 1)

  filters.value = formData.project?.length ? formData.project : ''
}
function isActiveProject(id) {
  return formData.project.findIndex((el) => el === id) !== -1
}
</script>

<style lang="scss">
.rotate {
  transform: rotate(180deg);
}

.col-filter {
  &__body {
    position: fixed;
    box-shadow: var(--box-shadow);
    z-index: 2002;
    width: 300px;

    &-section {
      min-height: 50px;
      max-height: calc(100vh - 350px);
    }
  }

  .search {
    &__label {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 40px;
    }
  }

  .check-list {
    &__item {
      border-radius: 100px;
      padding: 2px;

      &.active {
        background-color: var(--primary);
        border-color: var(--primary);

        svg {
          fill: #fff;
        }
      }
    }
  }

  .radio-list {
    label:hover {
      color: #000;
      background-color: var(--grey-l);
      cursor: pointer;
    }

    input:checked + label {
      color: #000;
    }
  }

  .statuses {
    &__tag {
      padding: 5px;
      background-color: transparent;
      border: 0;
      border-radius: var(--br);
      cursor: pointer;

      &:hover {
        background-color: var(--grey-l);
      }
    }
  }

  .projects {
    &__tag {
      padding: 10px;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      height: max-content;

      &:hover {
        background-color: var(--grey-l);
      }
    }
  }
}
</style>
