<template>
  <div class="flex ai-fe ggap-10">
    <FormAutocompleteSearch
      class="f-1"
      label="Исполнитель"
      placeholder="Выберите исполнителя"
      :options="optionsUsers"
      :isLoad="isLoad"
      :isMultiple="false"
      :required="required"
      @search="searchUsers($event)"
      v-model="propValue"
    >
      <template #single="{ item }">
        <div v-if="item && item.id" class="grid gtc-auto-1 ggap-10">
          <div class="cover ava-24">
            <img :src="item.avatar || require('@/assets/img/ava.svg')" />
          </div>
          <div class="grid gtc-auto-1 ai-c ggap-10">
            <div class="cut">{{ item.text }}</div>
            <!-- <div class="grid ai-c jc-fs">
              <small class="marker grey cut">{{ item.position }}</small>
            </div> -->
          </div>
        </div>
        <div v-else class="t-grey-dark">Выберите исполнителя</div>
      </template>
    </FormAutocompleteSearch>

    <button class="btn primary-light" type="button" @click.prevent="setResponsibleMe">
      <BaseIcon class="ic-16 primary" icon="user" />
      Назначить меня исполнителем
    </button>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { defineProps, ref, computed, defineEmits, toRefs } from 'vue'
import { BaseIcon, FormAutocompleteSearch } from '@/components'

// Emits
const emits = defineEmits(['update:modelValue'])

// Props
const props = defineProps({
  required: {
    type: Boolean,
    default: true
  },
  modelValue: {
    type: Object,
    default: () => ({})
  },
  only: {
    type: String,
    default: ''
  }
})

// Data
const { modelValue, required, only } = toRefs(props)
const store = useStore()
const isLoad = ref(false)

// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => emits('update:modelValue', val)
})
const optionsUsers = computed(() => store.getters.responsibles || [])
const profile = computed(() => store.getters.profile)

// Created
store.dispatch('GET_RESPONSIBLES')

// Methods
async function searchUsers(searchText) {
  isLoad.value = true
  await store.dispatch('GET_RESPONSIBLES', { searchText, only: only?.value })
  isLoad.value = false
}
function setResponsibleMe() {
  propValue.value = {
    id: profile.value?.id,
    avatar: profile.value?.avatar,
    position: profile.value?.specialization,
    text: profile.value?.fullname,
    value: profile.value?.id
  }
}
</script>
