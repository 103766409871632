<template>
  <div class="form-file grid ggap-10">
    <!-- List -->
    <div v-if="propValue.length" class="form-file__list grid">
      <ItemFile v-for="file of propValue" :key="file.id" :item="file" @remove="removeItem" />
    </div>

    <input
      type="file"
      name="file"
      id="file"
      class="form-file__input"
      @change="setFiles($event.target.files)"
      multiple
      accept=".svg,.jpg,.jpeg,.png,.gif,.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.txt,.zip,.rar"
    />
    <label for="file" class="form-file__label">
      <!-- Add -->
      <div v-if="propValue.length" class="flex jc-fs">
        <div class="btn primary-light sm" v-tippy="'Общий максимальный размер файлов<br>не более 50мб'">
          <BaseIcon class="ic-16 primary" icon="plus-circle" />
          Выбрать еще файлы
        </div>
      </div>

      <!-- Empty -->
      <div v-else class="form-file__empty flex fd-c ai-c ggap-5 p-20">
        <h4 class="title">Загрузите один или несколько файлов</h4>
        <small>pdf, doc, docx, xls, xlsx, jpg, png, gif, zip, rar</small>
        <small>Общий максимальный размер файлов не более 50мб</small>
        <div class="btn primary-light sm mt-10">
          <BaseIcon class="ic-16 primary" icon="plus-circle" />
          Выберите файлы
        </div>
      </div>
    </label>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, toRefs, computed } from 'vue'
import { BaseIcon, ItemFile } from '@/components'

// Emits
const emits = defineEmits(['update:modelValue'])

// Props
const props = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  }
})

// Data
const { modelValue } = toRefs(props)

// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => emits('update:modelValue', val)
})

// Methods
function setFiles(files) {
  for (let i = 0; i < files.length; i++) {
    const item = files[i]
    if (propValue.value.findIndex((el) => el.name === item.name) === -1) {
      propValue.value.push({
        id: item.lastModified,
        name: item.name,
        mime: item.type,
        size: item.size,
        file: item
      })
    }
  }

  const inputFile = document.getElementById('file')
  inputFile.value = ''
}
function removeItem(id) {
  const index = propValue.value.findIndex((item) => item.id === id)
  if (index !== -1) propValue.value.splice(index, 1)
}
</script>

<style lang="scss" scoped>
.form-file {
  &__input {
    display: none;
  }

  &__list {
    border-radius: var(--br);
    border: var(--b1);
  }

  &__empty {
    border: 2px var(--bcolor) dashed;
    border-radius: var(--br);
  }
}
</style>
