import axios from '../axios'

class EventsApi {
  getAll(id, s, types) {
    let url = `/api/sysadmin/history/${id}`
    if (s) url = `${url}?s_comment=${s}`
    if (types) url = `${url}${s ? '&' : '?'}types[]=${types}`
    return axios.get(url)
  }
  get(orderId, id) {
    return axios.get(`/api/sysadmin/${orderId}/history/${id}`)
  }
  update(id, payload) {
    return axios.patch(`/api/sysadmin/history/${id}`, payload)
  }
  comment(id, payload) {
    return axios.post(`/api/sysadmin/history`, payload)
  }
}

export default new EventsApi()
