import axios from '../axios'

class OrdersApi {
  getAll(options, sort, size, page) {
    let url = `/api/sysadmin?page=${page}&size=${size}`
    url += options.type ? `&type_id=${options.type}` : ''
    url += options.name ? `&name=${options.name}` : ''
    url += options?.owner?.id ? `&owner_id=${options.owner?.id}` : ''
    url += options?.responsible?.id ? `&responsible_id=${options?.responsible?.id}` : ''
    url += options?.for_user?.id ? `&for_user=${options?.for_user?.id}` : ''
    if (options.company?.length)
      for (let i = 0; i < options.company.length; i++) url += `&company_id[]=${options.company[i]}`
    if (options.status?.length) for (let i = 0; i < options.status.length; i++) url += `&status[]=${options.status[i]}`
    if (sort?.key) url += sort?.direction === 'asc' ? `&sort_asc[]=${sort.key}` : `&sort_desc[]=${sort.key}`
    return axios.get(url)
  }
  search(id) {
    return axios.get(`/api/sysadmin/search?s=${id}`)
  }
  get(id) {
    return axios.get(`/api/sysadmin/${id}`)
  }
  save(payload) {
    return axios.post('/api/sysadmin/draft', payload)
  }
  create(payload) {
    return axios.post('/api/sysadmin', payload)
  }
  update(id, payload) {
    return axios.post(`/api/sysadmin/${id}`, payload)
  }
  delete(id) {
    return axios.delete(`/api/sysadmin/${id}`)
  }
}

export default new OrdersApi()
