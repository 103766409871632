<template>
  <div
    class="form-item grid datepicker"
    :class="{ required: required, disabled: disabled, error: isError }"
    :data-success="isSuccess"
  >
    <label v-if="label" class="form-item__label">
      {{ label }}
    </label>
    <DatePicker
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      :lang="langObject"
      :append-to-body="false"
      :disabled-date="disabledDate"
      value-type="format"
      format="YYYY-MM-DD"
      v-model:value="propValue"
    />
  </div>
</template>

<script setup>
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import 'vue-datepicker-next/locale/ru'
import { defineProps, defineEmits, toRefs, computed } from 'vue'

// Emits
const emits = defineEmits(['update:modelValue'])

// Props
const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: 'Выберите дату'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  isError: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: [String, Date],
    default: ''
  }
})

// Data
const { label, placeholder, required, modelValue } = toRefs(props)
const langObject = {
  formatLocale: {
    firstDayOfWeek: 1
  },
  monthBeforeYear: false
}

// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => emits('update:modelValue', val)
})
const isSuccess = computed(() => {
  return !!propValue.value
})

// Methods
function disabledDate(date) {
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  // return date < today || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000)
  return date < today
}
</script>

<style lang="scss">
.mx-datepicker {
  width: 100%;
}
.mx-input {
  background-color: var(--form);
  box-shadow: inset 0 0 0 1px var(--bcolor);
  border-radius: var(--br);
  border: 0;
  color: var(--text);
  min-height: 36px;
  width: 100%;

  &:hover {
    box-shadow: inset 0 0 0 2px var(--bcolor);
  }

  &:focus,
  &.focused {
    box-shadow: inset 0 0 0 2px var(--primary);
  }
}
.datepicker.error .mx-input {
  box-shadow: inset 0 0 0 2px var(--red);
}
</style>
