<template>
  <div
    class="tabl__row flex"
    :class="{ deleted: row.status && row.status.id === 9 }"
    @click="$router.push('/orders/view/' + row.id)"
  >
    <template v-for="col of cols" :key="row.id + '-' + col.key">
      <div
        v-if="col.show && !col.disabled"
        class="tabl__row-cell grid ai-c jc-fs"
        :style="{ width: col.width, flex: col.flex }"
      >
        <!-- Status -->
        <template v-if="col.key === 'status'">
          <small
            v-if="row[col.key]"
            class="marker cut"
            :style="{ color: row[col.key].text_color, backgroundColor: row[col.key].background_color }"
          >
            {{ row[col.key].name }}
          </small>
          <template v-else>-</template>
        </template>

        <!-- Type -->
        <template v-else-if="col.key === 'type'">
          <span class="cut">
            {{ row[col.key] && row[col.key] === 'create' ? 'Создание документа' : 'Экспертиза' }}
          </span>
        </template>

        <!-- Project -->
        <template v-else-if="col.key === 'company'">
          <span class="cut">
            {{ row[col.key] && row[col.key].company ? row[col.key].company : '-' }}
          </span>
        </template>

        <!-- Created && Updated -->
        <template v-else-if="col.key === 'created_at' || col.key === 'updated'">
          <span class="cut">
            {{ row[col.key] ? formatRuDateTime(row[col.key]) : '-' }}
          </span>
        </template>

        <!-- Other -->
        <template v-else>
          <span class="cut">
            {{ row[col.key] ? row[col.key] : '-' }}
          </span>
        </template>
      </div>
    </template>
  </div>
</template>

<script setup>
// import moment from 'moment'
import { defineProps, toRefs } from 'vue'
import { formatRuDateTime } from '@/plugins'

// Props
const props = defineProps({
  cols: {
    type: Object,
    default: () => ({})
  },
  row: {
    type: Object,
    default: () => ({})
  }
})

// Data
const { cols, row } = toRefs(props)
</script>

<style lang="scss" scoped>
.tabl__row {
  background-color: var(--box);
  border-bottom: var(--b1);
  cursor: pointer;

  &:hover {
    background-color: var(--grey-el);
  }

  &:last-child {
    border-bottom: 0;
    box-shadow: inset 0 -1px 0 0 var(--bcolor);
  }

  &.deleted {
    color: var(--grey-ed);
  }

  &-cell {
    border-right: var(--b1);
    padding: 0 10px;
    height: 36px;

    &:last-child {
      border: 0;
    }
  }
}
</style>
